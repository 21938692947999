import type { FC } from "react";
import React from "react";

const IconMeasureRuler: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{ height: `1.6em`, width: `auto` }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.01237 7.40691C1.45002 6.84455 1.45002 5.93279 2.01237 5.37044L5.33241 2.05041C5.89476 1.48805 6.80652 1.48805 7.36887 2.05041L21.9292 16.6107C22.4916 17.1731 22.4916 18.0849 21.9292 18.6472L18.6092 21.9672C18.0468 22.5296 17.1351 22.5296 16.5727 21.9672L2.01237 7.40691ZM2.6912 6.04926C2.50375 6.23671 2.50375 6.54063 2.6912 6.72808L3.8367 7.87358L6.01123 5.69905C6.19868 5.5116 6.5026 5.5116 6.69005 5.69905C6.8775 5.8865 6.8775 6.19042 6.69005 6.37788L4.51552 8.55241L5.52825 9.56513L6.77789 8.31549C6.96534 8.12804 7.26926 8.12804 7.45671 8.31549C7.64416 8.50294 7.64416 8.80686 7.45671 8.99431L6.20707 10.244L7.21979 11.2567L9.39432 9.08215C9.58177 8.8947 9.88569 8.8947 10.0731 9.08215C10.2606 9.2696 10.2606 9.57352 10.0731 9.76097L7.89861 11.9355L8.90884 12.9457L10.1585 11.6961C10.3459 11.5086 10.6498 11.5086 10.8373 11.6961C11.0248 11.8835 11.0248 12.1875 10.8373 12.3749L9.58766 13.6245L10.5979 14.6348L12.7724 12.4602C12.9599 12.2728 13.2638 12.2728 13.4512 12.4602C13.6387 12.6477 13.6387 12.9516 13.4512 13.1391L11.2767 15.3136L12.2855 16.3224L13.5351 15.0727C13.7226 14.8853 14.0265 14.8853 14.2139 15.0727C14.4014 15.2602 14.4014 15.5641 14.2139 15.7515L12.9643 17.0012L13.9731 18.01L16.1476 15.8354C16.335 15.648 16.639 15.648 16.8264 15.8354C17.0139 16.0229 17.0139 16.3268 16.8264 16.5142L14.6519 18.6888L15.6428 19.6797L16.8925 18.4301C17.0799 18.2426 17.3838 18.2426 17.5713 18.4301C17.7587 18.6175 17.7587 18.9214 17.5713 19.1089L16.3216 20.3585L17.2515 21.2884C17.439 21.4759 17.7429 21.4759 17.9304 21.2884L21.2504 17.9684C21.4378 17.7809 21.4378 17.477 21.2504 17.2896L6.69005 2.72923C6.5026 2.54178 6.19868 2.54178 6.01123 2.72923L2.6912 6.04926Z"
        fill="#212121"
      />
    </svg>
  );
};

export default IconMeasureRuler;
